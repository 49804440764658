import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { useUser } from "src/hooks";
import { Textarea } from "src/shadcn/ui/textarea";
import { IJob, ITeam } from "src/models";
import { updateJobFirstStep } from "src/services/job";
import { Avatar, AvatarFallback, AvatarImage } from "src/shadcn/ui/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "src/shadcn/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/shadcn/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";

const formSchema = z.object({
  jobId: z.string().optional(),
  jobTitle: z.string().optional(),
  jobEmployer: z.string().optional(),
  currentTeam: z.string().min(1, { message: "Please select a team" }),
  recruitmentLead: z.string().optional(),
  email: z.string().optional(),
  companyLogo: z.string().optional(),
  note: z.string().optional(),
});

interface Props {
  currentJob: IJob;
  teams: ITeam[];
  setCurrentJob: Dispatch<SetStateAction<IJob | null>>;
}

type LogoCompanyType = {
  image?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (img: string) => void;
};

const LogoCompany: React.FC<LogoCompanyType> = ({ image, onChange }) => {
  const { data: userProfile } = useUser();

  // functions
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && !file.type.startsWith("image/")) {
      e.target.value = "";
      toast.error("Only JPG, JPEG and PNG files are allowed");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        onChange(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Avatar>
        <AvatarImage
          src={image}
          alt="Company logo"
          className="object-cover"
        />
        <AvatarFallback className="text-sm">{userProfile?.email?.slice(0, 2).toUpperCase()}</AvatarFallback>
      </Avatar>
      <label
        htmlFor="upload_avatar"
        className="text-xs border p-1 rounded-md text-foreground/70 cursor-pointer"
      >
        {image ? "Change" : "Upload"}

        <input
          type="file"
          id="upload_avatar"
          className="hidden"
          onChange={handleChangeFile}
        />
      </label>
    </div>
  );
};

const JobInformation: React.FC<Props> = ({ teams, currentJob }) => {
  const { data: userProfile } = useUser();
  const navigate = useNavigate();

  // states
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      jobId: "",
      jobTitle: "",
      jobEmployer: "",
      currentTeam: "",
      note: "",
      recruitmentLead: "",
      email: "",
      companyLogo: "",
    },
  });

  // functions
  const handleUpdateJob = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    const res = await updateJobFirstStep(currentJob.id, {
      title: values.jobTitle,
      employer: values.jobEmployer,
      ref: values.jobId,
      note: values.note,
      recruitment_lead: values.recruitmentLead,
      employer_email: values.email,
      company_logo: values.companyLogo,
      team_id: values.currentTeam,
    });
    setIsLoading(false);
    if (res) {
      navigate(`/job/${currentJob.id}?step=3`);
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleUpdateJob(values);
  };

  // effects
  useEffect(() => {
    if (currentJob) {
      form.setValue("currentTeam", currentJob.team_id);
      form.setValue("jobId", currentJob.ref || "");
      form.setValue("jobTitle", currentJob.title || "");
      form.setValue("jobEmployer", currentJob.employer || "");
      form.setValue("note", currentJob.note || "");
      form.setValue("recruitmentLead", currentJob.recruitment_lead || "");
      form.setValue("email", currentJob.employer_email || "");
      form.setValue("companyLogo", currentJob.company_logo || "");
    }
  }, [currentJob]);

  useEffect(() => {
    if (userProfile) {
      form.setValue("email", userProfile.email || "");
    }
  }, [userProfile]);

  return (
    <Card className="w-full h-full border-none shadow-none grow pb-14 relative">
      <CardHeader className="hidden">
        <CardTitle className="text-xl">Edit job information</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <FormField
                  control={form.control}
                  name="currentTeam"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel>Select team *</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a team" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {teams.map((team) => (
                            <SelectItem
                              key={team.id}
                              value={team.id}
                            >
                              {team.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                        <FormMessage className="text-xs" />
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="jobId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Job ID</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="123 (user enter)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="jobTitle"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Job title</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="BA (user enter)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="jobEmployer"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Employer</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="BA (user enter)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="recruitmentLead"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Recruitment lead</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter recruitment lead"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="companyLogo"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company logo</FormLabel>
                      <FormControl>
                        <LogoCompany
                          image={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <FormItem className="col-span-1 md:col-span-2 lg:col-span-3">
                      <FormLabel>Note</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Note for this job"
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mt-8 flex items-center justify-end gap-2 absolute bottom-0 right-0">
                <Button
                  type="button"
                  variant="outline_default"
                  className="mr-2"
                  onClick={() => navigate(`/job/${currentJob.id}?step=1`)}
                >
                  Back
                </Button>
                <Button
                  className="flex items-center gap-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && <Loader2 className="size-4 animate-spin" />}
                  Next step
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
};

export default JobInformation;
