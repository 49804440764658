import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { getTeams } from "src/services/team";
import { IPayment, IPlan } from "src/models";
import { getPaymentByTeamId } from "src/services/payment";
import { getSubscriptionByTeamId, getSubscriptionPlans } from "src/services/subscription";

import PlanCard from "../PlanCard";
import AddPaymentModal from "../AddPaymentModal";
import SubscribeNowModal from "../SubscribeNowModal";

interface Props {
  teamId: string;
}

const SubscriptionPlanNow: React.FC<Props> = ({ teamId }) => {
  const navigate = useNavigate();

  // states
  const [planId, setPlanId] = useState("");
  const [planList, setPlanList] = useState<IPlan[]>([]);
  const [payment, setPayment] = useState<IPayment | null>(null);
  const [currentPlan, setCurrentPlan] = useState<IPlan | null>(null);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [openSubscribeNowModal, setOpenSubscribeNowModal] = useState(false);
  const [isDefaultTeam, setIsDefaultTeam] = useState(false);

  // functions
  const handleGetSubscriptionPlans = async () => {
    const res = await getSubscriptionPlans();

    if (res) {
      const newRes = res.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      setPlanList([...newRes]);
    }
  };

  const handleGetSubscriptionByTeamId = async () => {
    if (!teamId) return;
    const res = await getSubscriptionByTeamId(teamId);

    if (res) {
      setCurrentPlan({ ...res });
    }
  };

  const handleGetPaymentMethodByTeamId = async () => {
    if (!teamId) return;
    const res = await getPaymentByTeamId(teamId);

    if (res) {
      setPayment({ ...res });
    }
  };

  const handleGetPaymentMethodByTeamIdCallback = async () => {
    if (!teamId) return;
    const res = await getPaymentByTeamId(teamId);

    if (res) {
      setPayment({ ...res });
      setOpenSubscribeNowModal(true);
    }
  };

  const handleGetTeams = async () => {
    const res = await getTeams();

    if (res && res?.length > 0) {
      const currentTeam = res.find((team) => team.is_default);

      if (currentTeam && currentTeam.id === teamId) {
        setIsDefaultTeam(true);
      }
    }
  };

  const handleSubscribeNow = (planId: string, planType: string) => {
    if (planType?.toUpperCase() === "ENTERPRISE") {
      window.open("https://acquiretalent.co/contact-us", "_blank");
    } else {
      setPlanId(planId);
      if (currentPlan?.id === planId) {
        navigate(`/subscription/${teamId}`);
      } else if (!payment) {
        // open add payment modal
        setOpenAddPaymentModal(true);
      } else {
        // open subscribe modal
        setOpenSubscribeNowModal(true);
      }
    }
  };

  // useEffects
  useEffect(() => {
    if (teamId) {
      handleGetSubscriptionPlans();
      handleGetSubscriptionByTeamId();
      handleGetPaymentMethodByTeamId();
      handleGetTeams();
    }
  }, [teamId]);

  return (
    <div>
      <h1 className="text-xl font-semibold">Choose a plan, and select the right talents today!</h1>
      <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8 mt-8">
        {planList &&
          planList?.length > 0 &&
          planList.map((plan) => {
            if (
              (currentPlan?.type &&
                currentPlan.type.toLowerCase().replace(/\s+/g, "_") !== "free_trial" &&
                plan.type.toLowerCase().replace(/\s+/g, "_") === "free_trial") ||
              (plan.type.toLowerCase().replace(/\s+/g, "_") === "free_trial" && !isDefaultTeam)
            ) {
              return null;
            }

            return (
              <PlanCard
                key={plan.id}
                plan={plan}
                currentPlanId={currentPlan?.id || ""}
                onSubscribeNow={handleSubscribeNow}
              />
            );
          })}
      </div>
      <ul className="mt-6 space-y-2 list-decimal pl-6">
        <li className="text-sm">Price listed in USD.</li>
      </ul>

      <AddPaymentModal
        open={openAddPaymentModal}
        teamId={teamId as string}
        onClose={() => {
          setOpenAddPaymentModal(false);
        }}
        callback={handleGetPaymentMethodByTeamIdCallback}
      />

      <SubscribeNowModal
        open={openSubscribeNowModal}
        planId={planId}
        teamId={teamId as string}
        paymentChannel={payment?.payment_channel || ""}
        setOpen={() => {
          setOpenSubscribeNowModal(false);
        }}
        callback={() => {
          setOpenSubscribeNowModal(false);
          handleGetSubscriptionPlans();
          handleGetSubscriptionByTeamId();
          handleGetPaymentMethodByTeamId();
        }}
      />
    </div>
  );
};

export default SubscriptionPlanNow;
