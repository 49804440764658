import React from "react";
import { Logo } from "src/components/common";
import { cn } from "src/lib/utils";
import { ModeToggle } from "src/shadcn/components/mode-toggle";

type Props = {
  children: React.ReactNode;
};

const CheckLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col max-w-screen-2xl mx-auto px-1 md:px-4">
      <header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="flex h-14 items-center">
          <div className="flex gap-8 items-center">
            <Logo />
          </div>
          <div className="flex-1 flex items-center justify-end gap-1">
            <ModeToggle />
          </div>
        </div>
      </header>
      <main className={cn("flex-1 flex flex-col pb-5 md:pb-10")}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};

export default CheckLayout;
