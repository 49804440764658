import { toast } from "sonner";
import { useNavigate, useLocation } from "react-router-dom";
import { Dispatch, SetStateAction, useState, useMemo, useEffect } from "react";
import { ListPlus, Pencil, RefreshCcwDot, PlusSquare, CircleCheck, Loader2 } from "lucide-react";

import { cn } from "src/lib/utils";
import { Button } from "src/shadcn/ui/button";
import { Checkbox } from "src/shadcn/ui/checkbox";
import { ICandidateAndRound, IJob, IReferenceCheck } from "src/models";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/tooltip";
import {
  assessmentCriteriaGenerate,
  createInterviewRound,
  setFinalizeJobInterviewRound,
  shortlistInterviewPerRound,
  generateInterview,
} from "src/services/job";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from "src/shadcn/ui/alert-dialog";

import InterviewQuestions from "../CreateCandidateShortlist/InterviewQuestions";
import InterviewRoundForm from "./InterviewRoundForm";

interface Props {
  currentJob: IJob;
  currentInterviewRoundNumber: number | null;
  candidatesAndRounds: ICandidateAndRound[] | null;
  currentReferenceList: IReferenceCheck[] | null;
  setCurrentJob?: Dispatch<SetStateAction<IJob | null>>;
  onCreateRoundCallBack?: () => void;
}

const Interviews = ({
  currentJob,
  candidatesAndRounds,
  currentInterviewRoundNumber,
  setCurrentJob,
  onCreateRoundCallBack,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  // states
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [candidateIdsByRound, setCandidateIdsByRound] = useState<{ [key: string]: string[] } | null>(null);
  const [isRetryGenerateLoading, setIsRetryGenerateLoading] = useState(false);
  const [isSetFinalRoundLoading, setIsSetFinalRoundLoading] = useState(false);
  const [isCreateNewRoundLoading, setIsCreateNewRoundLoading] = useState(false);

  // functions
  const handleCheckInterviewerCommentId = (candidateIds: string[]) => {
    const newInterviewerCommentIds = candidateIds.map(
      (id) => currentRound?.contents?.find((c) => c.resume_id === id)?.id || "",
    );

    return {
      res: newInterviewerCommentIds?.every((id) => id),
      ids: newInterviewerCommentIds,
    };
  };

  const handleGenerateInterviewQuestions = async () => {
    setIsGenerateLoading(true);
    const res = await generateInterview(currentJob.id);
    setIsGenerateLoading(false);

    if (res && setCurrentJob) {
      setCurrentJob({ ...res });
    }
  };

  const handleSetFinalRound = async (roundId: string) => {
    setIsSetFinalRoundLoading(true);
    const res = await setFinalizeJobInterviewRound(currentJob.id, roundId);
    setIsSetFinalRoundLoading(false);

    if (res && onCreateRoundCallBack) {
      onCreateRoundCallBack();
    }
  };

  const handleCreateInterviewRound = async () => {
    setOpenAlertDialog(false);

    const finalRound = candidatesAndRounds?.find((r) => r.round?.final);

    if (finalRound) {
      toast.error(`You have already set a final round ${finalRound.roundNumber}`);
      return;
    }

    setIsLoading(true);
    const res = await createInterviewRound(currentJob.id);
    setIsLoading(false);

    if (res && onCreateRoundCallBack) {
      onCreateRoundCallBack();
      navigate(`${location.pathname}?step=6&roundNumber=${res.round}`);
    }
  };

  const handleCreateNewRound = async () => {
    const nextRoundNumber = (currentInterviewRoundNumber ?? 0) + 1;
    const nextRoundCandidateIds = candidateIdsByRound?.[nextRoundNumber];

    if (!nextRoundCandidateIds || nextRoundCandidateIds.length === 0) {
      setOpenAlertDialog(true);
      return;
    }

    const currentCheckedCandidates = currentRound?.contents?.filter((c) => nextRoundCandidateIds.includes(c.resume_id));

    setIsCreateNewRoundLoading(true);
    const [res1, res2] = await Promise.all([
      shortlistInterviewPerRound(
        currentJob.id,
        currentRound?.round?.id ?? "",
        currentCheckedCandidates?.map((c) => c.id) ?? [],
      ),
      createInterviewRound(currentJob.id),
    ]);
    setIsCreateNewRoundLoading(false);

    if (res1 && res2) {
      onCreateRoundCallBack?.();
      navigate(`${location.pathname}?step=6&roundNumber=${res2.round}`);
    }
  };

  const handleAddNewRow = () => {};

  const handleRetryAssessmentGenerate = async () => {
    setIsRetryGenerateLoading(true);
    const res = await assessmentCriteriaGenerate(currentJob.id);
    setIsRetryGenerateLoading(false);

    if (res && setCurrentJob) {
      setCurrentJob({ ...res });
    }
  };

  const handleBack = () => {
    if (currentInterviewRoundNumber === 0) {
      navigate(`${location.pathname}?step=5`);
      return;
    }

    navigate(`${location.pathname}?step=6&roundNumber=${(currentInterviewRoundNumber ?? 0) - 1}`);
  };

  const handleNextStep = async () => {
    if (currentInterviewRoundNumber === 0) {
      navigate(`${location.pathname}?step=6&roundNumber=1`);
      return;
    }

    const currentRoundNumber = currentInterviewRoundNumber ?? 0;
    const nextRoundNumber = currentRoundNumber + 1;
    const candidateIdsCanShortList = currentRound?.round?.final
      ? candidateIdsByRound?.["candidate_proceed"] ?? []
      : candidateIdsByRound?.[nextRoundNumber] ?? [];

    if (candidateIdsCanShortList?.length === 0) {
      toast.warning("Please select at least one candidate to proceed to the next round!");
      return;
    }

    if (!currentRound?.round?.final && currentRound?.lastRound) {
      toast.warning("Please create a new round to proceed to the next step!");
      return;
    }

    const { res, ids } = handleCheckInterviewerCommentId(candidateIdsCanShortList);

    if (!res) {
      toast.error("Please upload or enter interview notes before proceeding");
      return;
    }

    setIsLoading(true);
    const res1 = await shortlistInterviewPerRound(currentJob.id, currentRound?.round?.id ?? "", ids);
    setIsLoading(false);

    if (res1 && onCreateRoundCallBack) {
      onCreateRoundCallBack();

      if (currentRound?.round?.final) {
        navigate(`${location.pathname}?step=7`);
      } else {
        navigate(`${location.pathname}?step=6&roundNumber=${nextRoundNumber}`);
      }
    }
  };

  const handleCheckedNextProceed = async (checked: boolean | "indeterminate", candidateId: string) => {
    if (currentRound?.round?.final) {
      setCandidateIdsByRound((prev) => ({
        ...(prev ?? {}),
        candidate_proceed: checked
          ? [...(prev?.["candidate_proceed"] ?? []), candidateId]
          : (prev?.["candidate_proceed"] ?? [])?.filter((id) => id !== candidateId),
      }));
    } else {
      setCandidateIdsByRound((prev) => ({
        ...(prev ?? {}),
        [(currentRound?.roundNumber ?? 0) + 1]: checked
          ? [...(prev?.[(currentRound?.roundNumber ?? 0) + 1] ?? []), candidateId]
          : (prev?.[(currentRound?.roundNumber ?? 0) + 1] ?? [])?.filter((id) => id !== candidateId),
      }));
    }
  };

  // effects
  useEffect(() => {
    if (!candidatesAndRounds) return;

    const candidates: { [key: string]: string[] } = {};
    candidatesAndRounds.forEach((round) => {
      candidates[round.roundNumber] = round.candidates.map((c) => c.id);

      if (round.lastRound) {
        candidates["candidate_proceed"] = round.contents?.map((s) => s?.resume_id);
      }
    });

    setCandidateIdsByRound({ ...candidates });
  }, [candidatesAndRounds]);

  // memos
  const currentRound = useMemo(() => {
    const round = candidatesAndRounds?.find((r) => r.roundNumber === currentInterviewRoundNumber);

    return round;
  }, [currentInterviewRoundNumber, candidatesAndRounds]);

  const disableCreateRound = useMemo(() => {
    if (!candidatesAndRounds) return false;
    const lastRound = candidatesAndRounds[candidatesAndRounds.length - 1];

    return lastRound?.roundNumber !== currentInterviewRoundNumber;
  }, [currentInterviewRoundNumber, candidatesAndRounds]);

  return (
    <>
      <div className="mb-2 shrink-0 flex items-center justify-between">
        <p className="text-base md:text-xl font-bold">
          {currentInterviewRoundNumber === 0
            ? "Generate Interview Questions"
            : `Interview Round ${currentInterviewRoundNumber}`}
        </p>
        {currentRound?.round && currentRound?.round?.round > 0 && (
          <button
            className="text-xs h-fit w-fit px-2 py-2 rounded-md bg-emerald-500 disabled:bg-emerald-300 text-white flex items-center gap-1"
            disabled={currentRound?.round?.final || isSetFinalRoundLoading}
            onClick={() => handleSetFinalRound(currentRound?.round?.id)}
          >
            {isSetFinalRoundLoading && <Loader2 className="animate-spin size-4" />}
            <p className="text-sm font-medium inline-flex items-center gap-1">
              {currentRound?.round?.final ? (
                <>
                  <CircleCheck className="size-4" />
                  Final round
                </>
              ) : (
                "Set as final round"
              )}
            </p>
          </button>
        )}
      </div>
      <div className="grow relative w-full pb-14">
        {currentInterviewRoundNumber === 0 ? (
          <>
            <InterviewQuestions interviewQuestionsList={currentJob.interview_questions} />
            <div className="my-4 flex items-center justify-end">
              <span className="text-sm mr-2">Edit interviews questions</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      className="p-1 h-fit"
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      <Pencil className="size-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="dark:text-white text-black text-sm">Edit</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      className="p-1 h-fit"
                      onClick={handleAddNewRow}
                    >
                      <ListPlus className="size-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="dark:text-white text-black text-sm">Add new row</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      disabled={isRetryGenerateLoading}
                      className="p-1 h-fit"
                      onClick={handleRetryAssessmentGenerate}
                    >
                      <RefreshCcwDot className="size-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="dark:text-white text-black text-sm">Retry</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </>
        ) : (
          <div className="space-y-8">
            {currentRound?.candidates &&
              currentRound?.candidates?.length > 0 &&
              currentRound?.candidates.map((resume, index) => {
                let isSelected = false;

                if (currentRound?.round?.final) {
                  isSelected = !!(candidateIdsByRound?.["candidate_proceed"] ?? [])?.includes(resume.id);
                } else {
                  isSelected = !!(candidateIdsByRound?.[currentRound?.roundNumber + 1] ?? [])?.includes(resume.id);
                }

                return (
                  <div key={resume.id}>
                    <div className="flex items-center justify-end space-x-2 mb-2">
                      <Checkbox
                        id={`${currentRound?.round?.id}-${resume.id}`}
                        checked={isSelected}
                        onCheckedChange={(checked: boolean | "indeterminate") =>
                          handleCheckedNextProceed(checked, resume.id)
                        }
                        className="data-[state=checked]:bg-green-500 data-[state=checked]:text-primary-foreground data-[state=checked]:border-green-500 transition-all font-semibold"
                      />
                      <label
                        htmlFor={`${currentRound?.round?.id}-${resume.id}`}
                        className={cn(
                          "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer",
                          isSelected && "text-green-500",
                        )}
                      >
                        Proceed to next round
                      </label>
                    </div>
                    <InterviewRoundForm
                      index={index}
                      jobId={currentJob.id}
                      isSelected={isSelected}
                      roundId={currentRound?.round?.id ?? ""}
                      resume={resume}
                      content={currentRound?.contents?.find((c) => c.resume_id === resume.id)?.content ?? ""}
                      interviewId={currentRound?.contents?.find((c) => c.resume_id === resume.id)?.id ?? ""}
                      callBack={onCreateRoundCallBack}
                    />
                  </div>
                );
              })}
          </div>
        )}

        <div className="md:absolute md:bottom-0 md:right-0 w-full flex flex-col md:flex-row justify-between gap-4">
          <div>
            <Button
              type="button"
              disabled={disableCreateRound || isLoading || currentRound?.round?.final || isCreateNewRoundLoading}
              className={cn(
                "flex items-center gap-1",
                (currentInterviewRoundNumber === 0 || currentRound?.round?.final) && "hidden",
              )}
              onClick={handleCreateNewRound}
            >
              <PlusSquare className="size-5" />
              <span>Additional interview round</span>
            </Button>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4">
            <Button
              variant="outline_default"
              onClick={handleBack}
              className="w-full md:w-fit"
            >
              Back
            </Button>
            <Button
              className={cn(
                "flex items-center gap-1 bg-[#0C6478] hover:bg-[#15919B] w-full md:w-fit",
                currentInterviewRoundNumber !== 0 && "hidden",
              )}
              disabled={isGenerateLoading}
              onClick={handleGenerateInterviewQuestions}
            >
              {isGenerateLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Generate Interview Questions</span>
            </Button>
            <Button
              disabled={isLoading}
              className={cn(
                "flex items-center gap-1 w-full md:w-fit",
                !currentRound?.round?.final &&
                  currentRound?.lastRound &&
                  candidateIdsByRound?.[currentRound?.roundNumber]?.length === 0 &&
                  "hidden",
              )}
              onClick={handleNextStep}
            >
              {isLoading && <Loader2 className="animate-spin size-4" />}
              <p>
                {candidatesAndRounds && candidatesAndRounds.length > 0
                  ? `${
                      currentInterviewRoundNumber === candidatesAndRounds.length
                        ? "Next Step"
                        : `Move to round ${(currentInterviewRoundNumber ?? 0) + 1}`
                    }`
                  : "Create Round"}
              </p>
            </Button>
          </div>
        </div>
      </div>

      <AlertDialog
        open={openAlertDialog}
        onOpenChange={() => setOpenAlertDialog(false)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogDescription>
              Please select at least one candidate to proceed to the next round!
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button
              className="flex items-center gap-1"
              disabled={isLoading}
              onClick={() => {
                handleCreateInterviewRound();
              }}
            >
              {isLoading && <Loader2 className="animate-spin size-4" />}
              <span>Confirm</span>
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Interviews;
