import { ChevronsUp } from "lucide-react";
import { useState } from "react";

import { cn } from "src/lib/utils";

interface Props {
  children: React.ReactNode;
  titleElement: React.ReactNode;
  className?: string;
}

const Accordion: React.FC<Props> = ({ children, titleElement, className }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={cn("w-full flex items-center justify-between", className)}>
        <div className="grow">{titleElement}</div>
        <button
          type="button"
          className="w-fit h-fit p-1 rounded-full bg-slate-300 dark:bg-slate-400 hover:bg-slate-400 transition-all shrink-0"
          onClick={toggleAccordion}
        >
          <ChevronsUp className={cn("w-4 h-4 transition-all duration-200 text-black", isOpen && "rotate-180")} />
        </button>
      </div>
      <div
        className={cn(
          "w-full overflow-hidden transition-all duration-300 ease-in-out",
          isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0",
        )}
      >
        {children}
      </div>
    </>
  );
};

export default Accordion;
