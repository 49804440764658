import { ChevronsLeft, ChevronsRight, Loader2 } from "lucide-react";
import { useEffect, useState, useRef, useMemo } from "react";

import { cn } from "src/lib/utils";
import { Label } from "src/shadcn/ui/label";
import { Switch } from "src/shadcn/ui/switch";
import { Button } from "src/shadcn/ui/button";
import { Slider } from "src/shadcn/ui/slider";
import { baseURL } from "src/services/api";
import { IJob, IListReport, IReport } from "src/models";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "src/shadcn/ui/tab";
import { getReport, exportJobReport, generateInterview } from "src/services/job";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "src/shadcn/ui/table";

import ChatBox from "./ChatBox";
import InterviewQuestions from "./InterviewQuestions";
import { Progress } from "src/shadcn/ui/progress";
// let fullListReportOrigin: IListReport[] = [];

interface Props {
  currentJob: IJob;
  onBack: () => void;
  // eslint-disable-next-line no-unused-vars
  onUpdateCurrentJob: (job: IJob) => void;
  // eslint-disable-next-line no-unused-vars
  onUpdateChatResult: (results: { question: string; answer: string }[]) => void;
}

const CreateCandidateShortlist = ({ currentJob, onBack, onUpdateCurrentJob, onUpdateChatResult }: Props) => {
  // refs
  const generateEventSourceRef = useRef<EventSource | null>(null);

  // states
  const [listReportOrigin, setListReportOrigin] = useState<IReport>({
    short_list_report: [],
    full_list_report: [],
  });
  const [isShortList, setIsShortList] = useState(false);
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("candidate_shortlist");
  const [isExportReportLoading, setIsExportReportLoading] = useState(false);
  const [matchScore, setMatchScore] = useState([40]);
  const [alphabet, setAlphabet] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleGetReport = async () => {
    setIsGenerateLoading(true);
    const res = await getReport(currentJob.id);
    setIsGenerateLoading(false);

    if (res) {
      setListReportOrigin({ ...res });
    }

    setIsGenerateLoading(false);
  };

  const handleGenerateReport = async () => {
    if (generateEventSourceRef.current) {
      generateEventSourceRef.current.close();
    }

    setIsGenerateLoading(true);
    setListReportOrigin({
      short_list_report: [],
      full_list_report: [],
    });
    generateEventSourceRef.current = new EventSource(
      `${baseURL}/jobs/${currentJob.id}/report/generate/stream?token=${localStorage.getItem("access_token")}`,
    );

    generateEventSourceRef.current.onmessage = (e) => {
      if (e.data === "DONE") {
        setIsLoading(false);
        generateEventSourceRef.current?.close();
        return;
      }

      if (e.data) {
        try {
          setIsLoading(true);
          const dataJson = JSON.parse(e.data);

          const data = JSON.parse(dataJson);

          if (data) {
            setIsGenerateLoading((prev) => {
              return prev ? false : prev;
            });
          }

          if (data?.short_list) {
            setListReportOrigin((prev) => {
              return {
                ...prev,
                short_list_report: [...prev.short_list_report, data.short_list],
              };
            });
          } else if (data?.full_list) {
            setListReportOrigin((prev) => {
              return {
                ...prev,
                full_list_report: [...prev.full_list_report, data.full_list],
              };
            });
          }
        } catch (error) {
          console.error(error);
          setIsGenerateLoading(false);
          setIsLoading(false);
        }
      }
    };

    generateEventSourceRef.current.onerror = () => {
      setIsGenerateLoading(false);
      setIsLoading(false);
      generateEventSourceRef.current?.close();
    };
  };

  const handleCheckedChange = (value: boolean) => {
    setIsShortList(value);
  };

  const handleChangeTab = (tab: string) => {
    setCurrentTab(tab);
  };

  const handleGenerateInterviewQuestions = async () => {
    setIsGenerateLoading(true);
    const res = await generateInterview(currentJob.id);
    setIsGenerateLoading(false);

    if (res) {
      onUpdateCurrentJob({ ...res });
    }
  };

  const handleExportReport = async () => {
    let report: IListReport[] = [];

    if (isShortList) {
      report = listReport?.short_list_report || [];
    } else {
      report = listReport?.full_list_report || [];
    }

    setIsExportReportLoading(true);
    const res = await exportJobReport(currentJob.id, report);
    setIsExportReportLoading(false);

    if (res) {
      const blob = new Blob([res], {
        type: "application/pdf",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "report.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleNextAlphabet = () => {
    const index = currentAlphabetList.indexOf(alphabet);
    if (index < currentAlphabetList.length - 1) {
      setAlphabet(currentAlphabetList[index + 1]);
    }
  };

  const handlePrevAlphabet = () => {
    const index = currentAlphabetList.indexOf(alphabet);
    if (index > 0) {
      setAlphabet(currentAlphabetList[index - 1]);
    }
  };

  // effects
  useEffect(() => {
    handleGetReport();
  }, []);

  // memos
  const listReport = useMemo(() => {
    const currentScore = 100 - matchScore[0];
    const fullListReportByScore =
      (listReportOrigin?.full_list_report ?? []).filter((report) => report.score >= currentScore) ?? [];

    if (!alphabet) {
      return {
        short_list_report: listReportOrigin?.short_list_report ?? [],
        full_list_report: fullListReportByScore ?? [],
      };
    }

    return {
      short_list_report: (listReportOrigin?.short_list_report ?? [])?.filter((report) =>
        report.first_name?.toUpperCase().startsWith(alphabet),
      ),
      full_list_report: fullListReportByScore?.filter((report) =>
        report.first_name?.toUpperCase().startsWith(alphabet),
      ),
    };
  }, [matchScore, listReportOrigin, alphabet]);

  const currentAlphabetList: string[] = useMemo(() => {
    if (isShortList) {
      const shortListReport = listReportOrigin?.short_list_report ?? [];

      return Array.from(new Set(shortListReport.map((report) => report.first_name?.charAt(0).toUpperCase()))).sort();
    } else {
      const fullListReport = listReportOrigin?.full_list_report ?? [];

      return Array.from(new Set(fullListReport.map((report) => report.first_name?.charAt(0).toUpperCase()))).sort();
    }
  }, [isShortList, listReportOrigin]);

  const currentProgress = useMemo(() => {
    if (!isLoading) {
      return {
        progress: 0,
        total: 0,
      };
    }

    if (currentJob?.resumes && currentJob?.resumes?.length > 0) {
      const totalResumes = currentJob?.resumes?.length ?? 0;
      const totalShortList = listReportOrigin?.full_list_report?.length ?? 0;

      return {
        progress: Math.round((totalShortList / totalResumes) * 100),
        total: totalShortList,
      };
    }

    return {
      progress: 0,
      total: 0,
    };
  }, [listReportOrigin, currentJob, isLoading]);

  return (
    <>
      <Tabs
        defaultValue="candidate_shortlist"
        className="flex-1 flex flex-col relative"
        onValueChange={handleChangeTab}
      >
        <TabsList className="grid grid-cols-3 w-fit">
          <TabsTrigger value="candidate_shortlist">Candidate shortlist</TabsTrigger>
          <TabsTrigger value="qa_resumes">Q&A resumes</TabsTrigger>
          <TabsTrigger value="interview_questions">Interview questions</TabsTrigger>
        </TabsList>

        <TabsContent
          value="candidate_shortlist"
          className={cn(currentTab === "candidate_shortlist" && "flex-1 flex flex-col", isGenerateLoading && "hidden")}
        >
          <div className="flex justify-between items-center">
            <p className="text-sm font-semibold">Candidate Shortlist Report</p>
            <div className="flex items-center space-x-2">
              <Label
                className={cn("text-sm", isShortList && "text-foreground/45")}
                htmlFor="short-full-report"
              >
                All candidates
              </Label>
              <Switch
                id="short-full-report"
                defaultChecked={isShortList}
                onCheckedChange={handleCheckedChange}
              />
              <Label
                className={cn("text-sm", !isShortList && "text-foreground/45")}
                htmlFor="short-full-report"
              >
                Display only shortlisted
              </Label>
            </div>
          </div>
          <div className="mt-4 flex-1">
            <div className="flex items-center justify-between mb-6">
              <p className="text-sm">
                {isShortList
                  ? "The following candidates meet all “must have”, and all or some “desirable” requirements:"
                  : "Detailed assessment report for all candidates:"}
              </p>
              <div className={cn("hidden flex-col gap-2", !isShortList && "flex")}>
                <div className="flex items-center gap-2">
                  <div className="w-[150px]">
                    <Label>Filter by match score:</Label>
                  </div>
                  <Slider
                    defaultValue={[40]}
                    value={matchScore}
                    max={100}
                    step={1}
                    className="w-[250px]"
                    onValueChange={(value) => setMatchScore(value)}
                    dir="rtl"
                  />
                  <Label>100%</Label>
                </div>
                <p className="text-sm mt-4">
                  {listReport?.full_list_report?.length ?? 0} out of {listReportOrigin?.full_list_report?.length ?? 0}
                  &nbsp; candidates match
                </p>
              </div>
            </div>

            {isLoading && (
              <div className="w-full">
                <span className="text-sm font-semibold">
                  Assessing {currentProgress?.total} out of {currentJob?.resumes?.length} resumes
                </span>
                <div className="w-full flex items-center gap-2">
                  <span className="text-sm font-semibold">0%</span>
                  <div className="flex-1">
                    <Progress
                      className="h-2 text-sky-400 bg-sky-400"
                      value={currentProgress?.progress}
                    />
                  </div>
                  <span className="text-sm font-semibold">100%</span>
                </div>
              </div>
            )}

            <div className={cn("space-y-4", !isShortList && "hidden")}>
              {listReport?.short_list_report && listReport?.short_list_report?.length > 0 ? (
                <>
                  {listReport?.short_list_report.map((report, index) => (
                    <Card
                      key={`full-list-report-${report.email}-${index}`}
                      className={cn("p-3 py-5")}
                    >
                      <CardHeader className="hidden">
                        <CardTitle>Interview Questions</CardTitle>
                        <CardDescription>Short list description</CardDescription>
                      </CardHeader>
                      <CardContent className="p-0">
                        <div className="mb-3 flex flex-col md:flex-row md:justify-between p-2 rounded-tl-md rounded-tr-md bg-red-200">
                          <div>
                            <p className="text-sm font-semibold dark:text-black">
                              Candidate name:&nbsp;
                              {report.first_name} {report.last_name}
                            </p>
                            <p className="text-sm font-semibold dark:text-black">
                              Contact number:&nbsp;
                              {report.mobile}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm font-semibold dark:text-black">
                              Match:&nbsp;
                              {report.score}%
                            </p>
                            <p className="text-sm font-semibold dark:text-black">
                              Email:&nbsp;
                              {report.email}
                            </p>
                          </div>
                        </div>
                        <Table key={`short-list-${report.email}-${index}`}>
                          <TableCaption className="hidden">A list of your recent invoices.</TableCaption>
                          <TableHeader>
                            <TableRow>
                              <TableHead className="">Requirements</TableHead>
                              <TableHead>Type</TableHead>
                              <TableHead>Match</TableHead>
                              <TableHead>Details</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody className="">
                            {((report?.must_have && report.must_have?.length > 0) ||
                              (report?.desire_to_have && report?.desire_to_have?.length > 0)) &&
                              [...report.must_have, ...report.desire_to_have].map((item, i) => (
                                <TableRow key={`short-list-items-${index}-${i}`}>
                                  <TableCell>{item.criteria}</TableCell>
                                  <TableCell>Must have</TableCell>
                                  <TableCell>{item.satisfied}</TableCell>
                                  <TableCell>{item.details}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Card>
                  ))}
                </>
              ) : (
                <div className="h-[40vh] flex items-center justify-center">
                  <p className="text-sm font-bold text-center">Click Generate Report to view Candidate Shortlist</p>
                </div>
              )}
            </div>

            <div className={cn("space-y-4", isShortList && "hidden")}>
              {listReport?.full_list_report && listReport?.full_list_report?.length > 0 ? (
                <>
                  {listReport?.full_list_report.map((report, index) => (
                    <Card
                      key={`full-list-report-${report.email}-${index}`}
                      className={cn("p-3 py-5")}
                    >
                      <CardHeader className="hidden">
                        <CardTitle>Interview Questions</CardTitle>
                        <CardDescription>Short list description</CardDescription>
                      </CardHeader>
                      <CardContent className="p-0">
                        <div className="mb-3 flex flex-col md:flex-row md:justify-between p-2 rounded-tl-md rounded-tr-md bg-red-200">
                          <div>
                            <p className="text-sm font-semibold dark:text-black">
                              Candidate name:&nbsp;
                              {report.first_name} {report.last_name}
                            </p>
                            <p className="text-sm font-semibold dark:text-black">
                              Contact number:&nbsp;
                              {report.mobile}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm font-semibold dark:text-black">
                              Match:&nbsp;
                              {report.score}%
                            </p>
                            <p className="text-sm font-semibold dark:text-black">
                              Email:&nbsp;
                              {report.email}
                            </p>
                          </div>
                        </div>
                        <Table key={`short-list-${report.email}-${index}`}>
                          <TableCaption className="hidden">A list of your recent invoices.</TableCaption>
                          <TableHeader>
                            <TableRow>
                              <TableHead className="">Requirements</TableHead>
                              <TableHead>Type</TableHead>
                              <TableHead>Match</TableHead>
                              <TableHead>Details</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody className="">
                            {((report?.must_have && report.must_have?.length > 0) ||
                              (report?.desire_to_have && report?.desire_to_have?.length > 0)) &&
                              [...report.must_have, ...report.desire_to_have].map((item, i) => (
                                <TableRow key={`short-list-items-${index}-${i}`}>
                                  <TableCell>{item.criteria}</TableCell>
                                  <TableCell>Must have</TableCell>
                                  <TableCell>{item.satisfied}</TableCell>
                                  <TableCell>{item.details}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Card>
                  ))}
                </>
              ) : (
                <div className="h-[40vh] flex items-center justify-center">
                  <p className="text-sm font-bold text-center">Click Generate Report to view Candidate Shortlist</p>
                </div>
              )}
            </div>
          </div>

          <div
            className={cn(
              "w-full flex justify-between items-center my-8",
              currentAlphabetList?.length === 0 && "hidden",
            )}
          >
            <p className="text-sm font-semibold">Sort by First Name A-Z:</p>
            <div className="flex items-center gap-2 flex-wrap justify-center">
              <Button
                variant="outline_default"
                className="rounded-full flex items-center justify-center w-8 h-8 p-0"
                onClick={handlePrevAlphabet}
              >
                <ChevronsLeft className="size-4" />
              </Button>
              {currentAlphabetList &&
                currentAlphabetList?.length > 0 &&
                currentAlphabetList.map((al) => (
                  <Button
                    key={al}
                    variant={alphabet === al ? "default" : "outline"}
                    className="w-8 h-8 rounded-full flex items-center justify-center"
                    onClick={() => setAlphabet(al)}
                  >
                    {al}
                  </Button>
                ))}
              <Button
                variant="outline_default"
                className="rounded-full flex items-center justify-center w-8 h-8 p-0"
                onClick={handleNextAlphabet}
              >
                <ChevronsRight className="size-4" />
              </Button>
            </div>
            <div />
          </div>

          <div className="flex justify-end items-center gap-2">
            <Button
              variant="outline_default"
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              className="flex items-center gap-1"
              disabled={isGenerateLoading}
              onClick={handleGenerateReport}
            >
              {isGenerateLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Generate Report</span>
            </Button>
            <Button
              variant="secondary"
              className="flex items-center gap-1"
              disabled={isExportReportLoading}
              onClick={handleExportReport}
            >
              {isExportReportLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Export Report</span>
            </Button>
          </div>
        </TabsContent>

        <TabsContent
          value="qa_resumes"
          className={cn(isGenerateLoading && "hidden")}
        >
          {/* <ChatBox
            currentJob={currentJob}
            onUpdateChatResult={onUpdateChatResult}
          /> */}
        </TabsContent>

        <TabsContent
          value="interview_questions"
          className={cn(currentTab === "interview_questions" && "flex-1 flex flex-col", isGenerateLoading && "hidden")}
        >
          <div className="flex-1">
            <InterviewQuestions interviewQuestionsList={currentJob.interview_questions} />
          </div>
          <div className="flex justify-end items-start gap-2 mt-8">
            <Button
              variant="outline_default"
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              className="flex items-center gap-1"
              disabled={isGenerateLoading}
              onClick={handleGenerateInterviewQuestions}
            >
              {isGenerateLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Generate Interview Question</span>
            </Button>
          </div>
        </TabsContent>

        {isGenerateLoading && (
          <div className="w-full flex h-[45vh] justify-center items-center absolute top-1/2 left-0">
            <Loader2 className="size-9 text-blue-500 animate-spin" />
          </div>
        )}

        {/* {isLoading && (
          <div className="absolute top-0 right-0 w-[300px] flex items-center gap-2">
            <span className="text-sm font-semibold">0%</span>
            <div className="flex-1">
              <Progress
                className="h-2 text-sky-400 bg-sky-400"
                value={currentProgress}
              />
            </div>
            <span className="text-sm font-semibold">100%</span>
          </div>
        )} */}
      </Tabs>
    </>
  );
};

export default CreateCandidateShortlist;
